/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Component, TemplateRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentService } from '../core/services/environment.service';
import { VestaKeycloakTokenParsed } from '../user/model/vesta-keycloak-token-parsed';
import { HttpClient } from '@angular/common/http';
import { Role } from '../configuration/model/layout-settings';
import { RolePermission } from '../core/model/role-permission';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService, MsiModalRef } from '@msi/cobalt';
import { from } from 'rxjs';

@Component({
    selector: 'msi-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
    public appName: string;
    public token: VestaKeycloakTokenParsed;
    private readonly CURRENT_ROLE_PREFIX = '/api/config-mgmt/v1/users';
    private readonly CURRENT_ROLE_SUFFIX = 'current-role';
    private readonly USER_ROLES_BY_PERMISSION_URL = '/api/config-mgmt/v1/roles/role-by-permission';
    public filteredRoles: Role[];
    private msiModalRef: MsiModalRef;

    constructor(
        public router: Router,
        public env: EnvironmentService,
        private http: HttpClient,
        public keycloakService: KeycloakService,
        private modalService: ModalService
    ) {
        this.appName = env.environment.APP_NAME || 'VESTA NXT';
        this.token = this.keycloakService.getKeycloakInstance()?.tokenParsed as VestaKeycloakTokenParsed;
        console.log(`token`, this.token);
        const currentRole = this.token?.preferred_role;
        const roles$ = this.fetchUserRolesByPermission(RolePermission['call-handling-ui']);
        roles$.pipe(take(1)).subscribe((roles) => {
            this.filteredRoles = roles.filter((role) => this.token.roles.includes(role.roleName) && role.roleName !== currentRole);
        });
    }

    public changeRole(roleName: string) {
        this.http
            .put(`${this.env.environment.SKIPPER_BASE_URL}${this.CURRENT_ROLE_PREFIX}/${this.token.sub}/${this.CURRENT_ROLE_SUFFIX}`, {
                roleName: roleName
            })
            .subscribe(() => {
                this.redirectToHome();
            });
    }

    public redirectToHome() {
        this.router.navigate(['/']).then(() => {
            window.location.reload();
        });
    }

    public fetchUserRolesByPermission(permission: string) {
        return this.http.get<Role[]>(`${this.env.environment.SKIPPER_BASE_URL}${this.USER_ROLES_BY_PERMISSION_URL}/${permission}`);
    }

    public signOut(tpl: TemplateRef<any>) {
        this.msiModalRef = this.modalService.open(tpl, {
            hasBackdrop: true,
            disableClose: true,
            minWidth: '25vw'
        });
    }

    public cancel() {
        this.msiModalRef.close();
    }

    public confirm() {
        from(this.keycloakService.logout(window.location.origin)).subscribe();
    }
}
