/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { AgentStatus, Call, LocalParticipant } from 'CalltakingCoreApi';

export class AcdFunctions {

    public static computeStatus(userRequestedStatus: AgentStatus, agentStatus: AgentStatus, phoneRegistered: boolean, anyAssignedCall: boolean, anyActiveNonAcdCalls: boolean, anyHeldNonIgnoredCalls: boolean, anyActiveAcdCalls: boolean, pendingWrapUp: boolean): AgentStatus {
        if (!phoneRegistered) {
            return 'PHONE_UNAVAILABLE';
        }
        if (anyActiveAcdCalls) {
            return 'ON_ACD_CALL';
        }
        if (userRequestedStatus === 'READY' && anyAssignedCall) {
            return 'ASSIGNED_CALL';
        }
        if (anyActiveNonAcdCalls) {
            return 'ON_CALL';
        }
        if (anyHeldNonIgnoredCalls) {
            return 'HOLDING_CALL';
        }
        if (userRequestedStatus === 'READY' && agentStatus === 'ON_ACD_CALL' && pendingWrapUp) {
            return 'WRAPUP';
        }
        return userRequestedStatus;
    }

    public static isBusy(status: AgentStatus) {
        switch (status) {
        case "ON_CALL":
        case "ON_ACD_CALL":
        case "HOLDING_CALL":
        case "WRAPUP":
            return true;
        default:
            return false;
        }
    }

    public static isReady(status: AgentStatus) {
        switch (status) {
        case "READY":
        case "ASSIGNED_CALL":
        case "ASSIGNED_ABANDONED_CALL":
            return true;
        default:
            return false;
        }
    }

    public static isUnavailable(status: AgentStatus) {
        switch (status) {
            case "UNKNOWN":
            case "PHONE_UNAVAILABLE":
                return true;
            default:
                return false;
        }
    }

    public static isOnCall(status: AgentStatus) {
        switch (status) {
        case "ON_CALL":
        case "ON_ACD_CALL":
            return true;
        default:
            return false;
        }
    }

    public static localParticipantFilter(username: string, call: Call) {
        return (lp: LocalParticipant) => (!lp.silentMonitor || lp.name === username) && ((call.status === 'ON_HOLD' && lp.held) || !lp.leftOn || call.status === 'RELEASED');
    }
}
