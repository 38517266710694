/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize, select } from '@ngrx/store';
import {
    analyticsHistoricalCallState,
    CallState,
    localCallState,
    localHistoricalCallState,
    remoteCallState,
    remoteHistoricalCallState,
    selectAllAnalyticsHistoricalCalls,
    selectAllAnalyticsRecentCalls,
    selectAllHistoricalCalls,
    selectAllLocalCalls,
    selectHistoricalRemoteCallEntities,
    selectLocalCallEntities,
    selectLocalHistoricalCallEntities,
    selectRemoteCallEntities
} from './call.reducer';
import {
    selectAcdIgnoredCalls,
    selectAgentStatus,
    selectAgentStatusRecord,
    selectApplicationPresence,
    selectAutoLogoff,
    selectBidsMap,
    selectConnectedClusterName,
    selectFilteredAgents,
    selectHasPendingWrapUp,
    selectMemoizedUserAuthenticationRecord,
    selectMostRecentlyAnsweredAcdNenaCallId,
    selectPendingBid,
    selectSupervisedUserCallQueueNames,
    selectUserCallQueueMap,
    selectUserCallQueueNames,
    selectUserMap,
    selectUsername,
    selectUserRequestedStatus,
    selectUsers
} from '../../user/+state/user.selectors';
import { CallFunctions } from '../util/call-functions';
import { selectHasWebsocketConnection, selectIsPhoneRegistered, selectMediaConnectionsByClusterNameMap } from './media.selectors';
import { SortFunctions } from '../util/sort-functions';
import { AgentStatus, Call, RemoteParticipant, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AcdFunctions } from '../../user/util/acd-functions';
import { BidResult } from '../../user/model/bid-result';
import { Dictionary } from '@ngrx/entity';
import { QueueDisplay } from '../../queue/queue-display-card/queue-display';
import { Bid } from '../../user/model/bid';
import {
    selectClusterConfigurationMap,
    selectCtcStatusByName,
    selectHasInitialized,
    selectHistoryDepth,
    selectUiConfiguration
} from '../../configuration/+state/configuration.selectors';
import {
    selectActiveAgentTableFilters,
    selectActiveCallTableFilters,
    selectActiveRecentCallFilters,
    selectActiveStandaloneCallTableFilters,
    selectMaxQueueDisplayRows
} from '../../settings/+state/settings.selectors';
import { AnalyticsHistoricalCall } from '../model/analytics-historical-call';
import {
    selectEnhancedLocationEvent,
    selectLocationDisplayTemplate_,
    selectServiceRespondersUrnMap,
    selectStandardLocationEvent,
    selectStandardLocationMap
} from '../../location/+state/location.selectors';
import { AgentTableData } from '../../supervisor/models/agent-table-data';
import { CallTableData } from '../../supervisor/models/call-table-data';
import { selectCcHubAudioOutputConnected, selectCcHubCallAudioInputConnected, selectCCHubConnectedAndInitialized } from './cchub.selectors';
import { holdSuccess } from './call.actions';
import { MediaService } from '../services/media.service';
import { v4 } from 'uuid';
import { AdrSpeedDial } from '../../directory/model/adr-speed-dial';
import { DirectoryItemType } from '../../directory/model/directory-item';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { CallbackUtil } from '../util/callback-util';

export const CALLS_FEATURE = 'calls';

export const selectCallState = createFeatureSelector<CallState>(CALLS_FEATURE);
export const selectLocalCallState = createSelector(selectCallState, localCallState);
export const selectHistoricalLocalCallState = createSelector(selectCallState, localHistoricalCallState);
export const selectRemoteCallState = createSelector(selectCallState, remoteCallState);
export const selectHistoricalRemoteCallState = createSelector(selectCallState, remoteHistoricalCallState);
export const selectAnalyticsHistoricalCallState = createSelector(selectCallState, analyticsHistoricalCallState);

// Since array.filter returns a new object, any selector that filters for example a calls list, will emit when the input changes, regardless of whether the filtered results change
// In order to make our selectors smarter, we provide it with a custom memorization function to compare the contents of the array, thus the selectors utilizing array.filter can emit only when the result changes.

export const arrayFilterMemoize = (projectorFn: DefaultProjectorFn<Call[]>) => resultMemoize(projectorFn, arrayIsEqual);
export const callMemoize = (projectorFn: DefaultProjectorFn<Call>) => resultMemoize(projectorFn, callIsSame);

export function arrayIsEqual(a: any[], b: any[]): boolean {
    return a?.length === b?.length && a?.every((item) => b?.includes(item));
}

export function callIsSame(a: Call, b: Call): boolean {
    return a?.uuid === b?.uuid;
}

export const selectCallStateSubscriptions = createSelector(selectCallState, (state) => state.subscriptions);
export const selectAllCallStateSubscriptionsConfirmed = createSelector(selectCallStateSubscriptions, (subscriptions) =>
    Object.values(subscriptions)
        .flatMap((sub) => Object.values(sub))
        .every((confirmed) => confirmed)
);
export const selectCallStateInitializationTime = (clusterName: string) => createSelector(selectAllCallStateSubscriptionsConfirmed, selectApplicationPresence, selectCtcStatusByName(clusterName), (subscribed, presence, ctcAvailable) =>
    Boolean(ctcAvailable && subscribed && presence.loggedIn && presence.loginTime) ? presence.loginTime : 0
);

export const selectWebsocketDisconnected = createSelector(selectHasWebsocketConnection, selectHasInitialized, (hasConnection, hasInitialized) => {
    return Boolean(hasInitialized && !hasConnection);
});

const _selectCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectLocalCallState, selectAllLocalCalls);
const selectCalls = createSelector(_selectCalls, selectMediaConnectionsByClusterNameMap, (calls, mediaConnectionsMap) => calls.filter((call) => mediaConnectionsMap[call.clusterName]?.registered));

export const selectCallsMap = createSelector(selectLocalCallState, selectLocalCallEntities);
export const selectHistoricalCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectHistoricalLocalCallState, selectAllHistoricalCalls);
export const selectHistoricalCallsMap = createSelector(selectHistoricalLocalCallState, selectLocalHistoricalCallEntities);
export const selectRemoteCallsMap = createSelector(selectRemoteCallState, selectRemoteCallEntities);
export const selectHistoricalRemoteCallsMap = createSelector(selectHistoricalRemoteCallState, selectHistoricalRemoteCallEntities);

const selectCall = (id: string) =>
    createSelector(selectCallsMap, selectHistoricalCallsMap, (callsMap, historicalCallsMap) => (callsMap[id] ? callsMap[id] : historicalCallsMap[id]));
export const getCall = (id: string) =>
    pipe(
        select(selectCall(id)),
        filter((val) => !!val),
        map((val) => val as Call)
    );

export const selectCallRedialUuid = (id: string) => createSelector(selectCall(id), (call) => call?.redialUUID);

const selectLinkedCalls_ = (callId: string) =>
    createSelector(selectCall(callId), selectRemoteCallsMap, selectHistoricalRemoteCallsMap, (call, remoteCallMap, historicalRemoteCallMap) =>
        call
            ? Object.values(call.linkedAgencies)
                  .filter((linkedCallId) => Boolean(remoteCallMap[linkedCallId] || historicalRemoteCallMap[linkedCallId]))
                  .map((linkedCallId) =>
                      remoteCallMap[linkedCallId] ? (remoteCallMap[linkedCallId] as Call) : (historicalRemoteCallMap[linkedCallId] as Call)
                  )
                  .sort(SortFunctions.oldestCreatedSort)
            : ([] as Call[])
    );

export const selectRemoteParticipant = (callId: string, remoteParticipantId: string) =>
    createSelector(selectCall(callId), (call) => call?.remoteParticipants.find((rp) => rp.uuid === remoteParticipantId) as RemoteParticipant);

export const selectCallIsReleased = (callId: string) => createSelector(
    selectCall(callId),
    (call) => CallFunctions.isReleased(call?.status)
);

export const selectLinkedRemoteParticipantCall = (callId: string, remoteParticipantId: string) =>
    createSelector(selectRemoteParticipant(callId, remoteParticipantId),
        selectLinkedCalls_(callId),
        selectCallIsReleased(callId),
        (remoteParticipant, linkedCalls, callIsReleased) => {
        return linkedCalls
            .filter((linkedCall) => !CallFunctions.isReleased(linkedCall.status) || callIsReleased)
            .find((linkedCall) => linkedCall.agencyId === remoteParticipant.remoteAgencyId) as Call;
    });

export const selectNetworkParticipantsLinkedCallMap = (callId: string) =>
    createSelector(selectCall(callId), selectLinkedCalls_(callId), (call, linkedCalls) =>
        CallFunctions.mapNetworkParticipantsToLinkedCalls(
            call,
            linkedCalls.filter((linkedCall) => !CallFunctions.isReleased(linkedCall.status) || CallFunctions.isReleased(call.status))
        )
    );

export const selectLinkedNetworkParticipantCall = (callId: string, networkParticipantId: string) =>
    createSelector(selectNetworkParticipantsLinkedCallMap(callId), (map) => map[networkParticipantId] as Call);

export const selectParticipantCount = (callId: string) =>
    createSelector(selectCall(callId), selectLinkedCalls_(callId), selectNetworkParticipantsLinkedCallMap(callId), selectUsername, (call, linkedCalls, networkParticipantCallMap, username: string) => {
        let networkParticipantCount = Object.entries(networkParticipantCallMap).filter(([,call]) => call === undefined).length;
        let calls = call ? [call, ...linkedCalls] : [];
        return calls.reduce(
            (accumulator, c) => accumulator + CallFunctions.participantCount(c, username as string),
            networkParticipantCount
        );
    });

export const selectInboundRingingCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectCalls,
    selectMemoizedUserAuthenticationRecord,
    (calls: Call[], user: UserAuthenticationRecord) => calls.filter((call) => CallFunctions.isCallRingingUser(call, user))
);

export const selectQueueDisplay = createSelector(selectUserCallQueueMap, selectInboundRingingCalls, selectUsers, (callQueueMap, calls, agents) => {
    return Object.values(callQueueMap)
        .map(({ name, displayName }) => {
            // Get the earliest ringing call
            const dgCalls = calls.filter((c) => c.ringingCallQueues.includes(name)).sort(SortFunctions.oldestCreatedSort);
            // Get the total active agents in the queue
            const dgAgents = agents.filter((a) => a.associatedCallQueues.includes(name) && !a.logoutDate);
            // Get the most recent queueJoin for this queue/call
            const latestQueueJoin =
                dgCalls[0] && dgCalls[0].queueJoins?.length
                    ? dgCalls[0].queueJoins.filter((q) => q.queueName === name)?.sort(SortFunctions.newestCreatedSort)[0]
                    : undefined;
            return {
                queue: displayName ?? name,
                calls: dgCalls.length,
                agents: dgAgents.length,
                idle: latestQueueJoin ? new Date(latestQueueJoin.createdTimestamp).getTime() : undefined
            } as QueueDisplay;
        })
        .sort((a, b) => (a.queue < b.queue ? -1 : b.queue > a.queue ? 1 : 0));
});

export const selectQueueDisplayCount = createSelector(selectMaxQueueDisplayRows, selectQueueDisplay, (maxRows, display) =>
    display.length > maxRows ? maxRows : display.length
);

export const selectConnectedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectCalls,
    selectMemoizedUserAuthenticationRecord,
    (calls: Call[], user: UserAuthenticationRecord) => calls.filter((call) => CallFunctions.getCallStatus(call, user) === 'CONNECTED')
);
export const selectUnreleasedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectCalls, (calls: Call[]) =>
    calls.filter((call) => call.status !== 'RELEASED')
);
export const selectReleasedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectCalls, (calls: Call[]) =>
    calls.filter((call) => call.status === 'RELEASED')
);

export const selectReleasedCallCount = createSelector(selectReleasedCalls, (calls) => calls.length);

export const selectAbandonedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectCalls, (calls: Call[]) =>
    calls.filter((call) => CallFunctions.isAbandoned(call.status))
);
export const selectAbandonedCallCount = createSelector(selectAbandonedCalls, (calls) => calls.length);

export const selectLiveCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectCalls, selectUsername, (calls: Call[], username: string) =>
    username === null ? [] : calls.filter((call) => CallFunctions.isLive(call.status))
);

export const selectLiveSmsCallForNumber = (number: string) => createSelector(selectLiveCalls, (calls) => calls.find((call) => call.text && call.callback.includes(number)));

export const selectOpenedCallIds = createSelector(selectCallState, (state) => state.openedCalls);
export const selectOpenedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectOpenedCallIds,
    selectCallsMap,
    selectHistoricalCallsMap,
    (openedCallIds: string[], calls: Dictionary<Call>, historicalCalls: Dictionary<Call>) =>
        openedCallIds.map((callId) => (calls[callId] ? calls[callId] : historicalCalls[callId]))
);

export const selectLockedCallIds = createSelector(selectCallState, (state) => state.lockedCalls);

export const selectUnOpenedUnlockedHistoricalCalls = createSelector(
    selectHistoricalCalls,
    selectOpenedCallIds,
    selectLockedCallIds,
    (calls, openedCallIds, lockedCallIds) => calls.filter((call) => !openedCallIds.includes(call.uuid) && !lockedCallIds.includes(call.uuid))
);

export const selectObservationState = createSelector(selectCallState, (state) => state.observationState);
export const selectObservedUsername = createSelector(selectObservationState, (state) => state.observedUser);
export const selectIsObservationStateActive = createSelector(selectObservationState, (state) => state.state === 'active');

export const selectOpenCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectLiveCalls,
    selectObservedUsername,
    selectUsername,
    selectOpenedCalls,
    (liveCalls: Call[], observedUser: string, username: string,  historicalCalls: Call[]) =>
        liveCalls.filter((call) =>
                CallFunctions.isActiveParticipant(call, username) ||
                CallFunctions.isHeldParticipant(call, username) ||
                CallFunctions.isActiveParticipant(call, observedUser) ||
                CallFunctions.isHeldParticipant(call, observedUser))
            .concat(historicalCalls)
);

export const selectMyCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectOpenCalls,
    selectUsername,
    (calls: Call[], username: string) => username === null ? [] :
        calls.filter((call) =>
            CallFunctions.isActiveParticipant(call, username) ||
            CallFunctions.isHeldParticipant(call, username))
);

export const selectHasCall = createSelector(selectMyCalls, (calls) => calls && calls.length);

export const selectCallsForAgent = (username: string) =>
    createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectLiveCalls, (calls: Call[]) =>
        calls.filter((call) => CallFunctions.isActiveParticipant(call, username) || CallFunctions.isHeldParticipant(call, username))
    );

export const selectMyActiveCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectLiveCalls,
    selectUsername,
    (calls: Call[], username: string) => (username === null ? [] : calls.filter((call) => CallFunctions.isActiveParticipant(call, username)).sort(SortFunctions.textLastSort))
);

export const selectAllActiveCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectLiveCalls, (calls: Call[]) =>
    calls.filter((call) => CallFunctions.hasActiveParticipants(call))
);

export const selectDiscrepancyReportCalls = createSelector(selectOpenCalls, selectHistoricalCalls, (myOpenCalls: Call[], historicalCalls: Call[]) => {
    return myOpenCalls.concat(historicalCalls.filter((historicalCall) => myOpenCalls.every((openCall) => historicalCall.uuid !== openCall.uuid)));
});

export const selectActiveCallId = createSelector(selectCallState, (state) => state.activeCallId);

export const selectActiveCall = createSelector(selectActiveCallId, selectCallsMap, (activeCallId, callsMap) =>
    activeCallId ? callsMap[activeCallId] : undefined
);

export const selectSelectedCallId = createSelector(selectCallState, (state) => state.selectedCallId);

export const selectSelectedCall = createSelector(
    selectCallsMap,
    selectHistoricalCallsMap,
    selectSelectedCallId,
    (callsMap, historicalCallsMap, selectedCallId) =>
        selectedCallId ? (callsMap[selectedCallId] ? callsMap[selectedCallId] : historicalCallsMap[selectedCallId]) : null
);

export const selectHasSelectedCall = createSelector(selectSelectedCall, (call) => Boolean(call));

export const selectActiveOrSelectedCall = createSelector(selectActiveCall, selectSelectedCall, (activeCall, selectedCall) => activeCall || selectedCall);

export const selectPendingHeldCall = createSelector(selectCallState, (state) => state.pendingHeldCall);

export const selectPendingHeldCallCallback = createSelector(selectCallState, (state) => state.heldCallCallback);

export const selectHoldSuccess = createSelector(
    selectCallsMap,
    selectUsername,
    selectPendingHeldCall,
    selectPendingHeldCallCallback,
    (callsMap, username, pendingHeldCall, callback) =>
        pendingHeldCall && CallFunctions.isHeld(callsMap[pendingHeldCall] as Call, username)
            ? holdSuccess({ callId: pendingHeldCall, callback: callback })
            : undefined
);

export const selectComputeActiveCallId = createSelector(
    selectActiveCallId,
    selectMyActiveCalls,
    selectSelectedCallId,
    (activeCallId, availableActiveCalls, selectedCallId) => {
        let currentActiveCallIsValid = Boolean(activeCallId && availableActiveCalls.map((call) => call.uuid).includes(activeCallId));
        let selectedCallOverride = Boolean(selectedCallId && availableActiveCalls.map((call) => call.uuid).includes(selectedCallId));
        return selectedCallOverride ? selectedCallId : currentActiveCallIsValid ? activeCallId : availableActiveCalls[0]?.uuid;
    }
);

export const selectActiveVoiceCall = createSelector(selectMyActiveCalls, (calls: Call[]) => calls.find((call) => !call.text));

export const selectIsConnectedToSelectedCall = createSelector(selectSelectedCall, selectUsername, (call, username) =>
    Boolean(call && username && !call.participants[username]?.leftOn)
);


export const selectHasActiveCall = createSelector(selectActiveCall, (call) => Boolean(call));

export const selectHasActiveVoiceCall = createSelector(selectActiveVoiceCall, (call) => Boolean(call));

// Consider calls I'm marked as the acdAssignedAgent AND calls I have a won bid on as assigned since we may get the bid result before the call is marked as assigned
export const selectAssignedCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectInboundRingingCalls,
    selectUsername,
    selectBidsMap,
    (ringingCalls: Call[], username: string, bidsMap: Dictionary<BidResult>) =>
        username ? ringingCalls.filter((call) => call.acdAssignedAgent === username || bidsMap[call.uuid]?.result === 'WON') : []
);

// includes previously queued acd calls that are no longer in an acd queue (aka i'm being conference in on someone's acd delivered call or the calls put into a ring all queue)
export const selectNonAcdCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(selectInboundRingingCalls, selectUsername, (ringingCalls: Call[]) =>
    ringingCalls.filter((call) => call.queueJoins.every((queueJoin) => !queueJoin.acd || (queueJoin.acd && queueJoin.leftOn)))
);

export const selectMyInboundCalls = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectAssignedCalls,
    selectNonAcdCalls,
    (assignedCalls: Call[], nonAssignedCalls: Call[]) =>
        assignedCalls.concat(nonAssignedCalls)
);

export const selectAssignedCall = createSelector(selectAssignedCalls, (assignedCalls) => assignedCalls.slice(0, 1)[0]);

export const selectChatOpen = createSelector(selectCallState, (state) => state.chatOpen);

export const selectNextCallToAnswer = createSelector(
    selectAssignedCalls,
    selectNonAcdCalls,
    (assignedCalls, ringingNonAcdCalls) => assignedCalls.concat(ringingNonAcdCalls)[0]
);

/* ******************* ACD ******************* */

export const selectHasAnyActiveAcdCalls = createSelector(selectMyCalls, selectUsername, (calls: Call[], username: string | undefined) =>
    Boolean(username && calls.some((call) => call.acd && !call.participants[username].leftOn))
);

export const selectHasAnyActiveNonAcdCalls = createSelector(selectMyCalls, selectUsername, (calls: Call[], username: string | undefined) =>
    Boolean(username && calls.some((call) => !call.acd && !call.participants[username].leftOn))
);

export const selectHasAnyHeldNonIgnoredCalls = createSelector(selectMyCalls, selectAcdIgnoredCalls, (calls: Call[], acdIgnoredCalls: {}) =>
    // @ts-ignore
    calls.some((call) => call.status === 'ON_HOLD' && !acdIgnoredCalls[call.uuid])
);

export const selectHasAssignedCall = createSelector(selectAssignedCalls, (calls: Call[]) => Boolean(calls.length));

export const selectComputedAgentStatus = createSelector(
    selectUserRequestedStatus,
    selectAgentStatus,
    selectIsPhoneRegistered,
    selectHasAssignedCall,
    selectHasAnyActiveNonAcdCalls,
    selectHasAnyHeldNonIgnoredCalls,
    selectHasAnyActiveAcdCalls,
    selectHasPendingWrapUp,
    selectConnectedClusterName,
    (userRequestedStatus, agentStatus, phoneRegistered, anyAssignedCall, anyActiveNonAcdCalls, anyHeldNonIgnoredCalls, anyActiveAcdCalls, pendingWrapUp, primaryCtc) =>
        primaryCtc ? AcdFunctions.computeStatus(
            userRequestedStatus,
            agentStatus,
            phoneRegistered,
            anyAssignedCall,
            anyActiveNonAcdCalls,
            anyHeldNonIgnoredCalls,
            anyActiveAcdCalls,
            pendingWrapUp
        ) : undefined
);

export const selectAssignedWonBid = createSelector(selectBidsMap, selectAssignedCall, selectAgentStatus, (bidsMap, assignedCall, agentStatus) =>
    assignedCall && agentStatus === 'ASSIGNED_CALL' && bidsMap[assignedCall.uuid] ? bidsMap[assignedCall.uuid] : undefined
);

export const selectAutoAnswerAssignedWonBid = createSelector(selectAssignedWonBid, selectUserCallQueueMap, (assignedWonBid, acdQueueMap) => {
    let autoAnswer = assignedWonBid && acdQueueMap[assignedWonBid.queueName]?.queueACD.answerMethod.autoAnswer;
    return assignedWonBid && autoAnswer ? assignedWonBid : undefined;
});

export const selectCallsToReject = createSelectorFactory<object, Call[]>(arrayFilterMemoize)(
    selectAssignedCalls,
    selectAgentStatus,
    (assignedCalls: Call[], agentStatus: AgentStatus) => (agentStatus === 'NOT_READY' ? assignedCalls : [])
);

export const selectBids = createSelector(
    selectInboundRingingCalls,
    selectBidsMap,
    selectMostRecentlyAnsweredAcdNenaCallId,
    (ringingCalls, bidsMap, previousNenaId) => {
        return ringingCalls
            .sort(SortFunctions.acdPrioritySort)
            .flatMap((call) =>
                call.queueJoins.map(
                    (queueJoin) =>
                        ({
                            callId: call.uuid,
                            nenaId: call.nenaCallId,
                            clusterName: call.clusterName,
                            queueJoin: queueJoin,
                            previouslyAssigned: Boolean(call.nenaCallId && call.nenaCallId === previousNenaId)
                        } as Bid)
                )
            )
            .filter(
                (bid) =>
                    bid.queueJoin.acd &&
                    !bid.queueJoin.leftOn &&
                    bid.queueJoin.auctionId &&
                    !Boolean(bidsMap[bid.callId] && bidsMap[bid.callId]?.auctionId === bid.queueJoin.auctionId)
            );
    }
);

const selectNextBid_ = createSelector(
    selectHasAssignedCall,
    selectPendingBid,
    selectAgentStatusRecord,
    selectUserCallQueueMap,
    selectBids,
    (assignedCall, pendingBid, agentStatus, callQueueMap, availableBids) => {
        let bid: Bid | undefined;
        if (!assignedCall && !pendingBid && agentStatus && availableBids.length) {
            bid =
                agentStatus.status === 'READY'
                    ? availableBids[0]
                    : agentStatus.status === 'ON_CALL'
                    ? availableBids.find((bid) => !bid.queueJoin.autoAnswer && callQueueMap[bid.queueJoin.queueName].queueACD.prioritizeRingOne)
                    : undefined;
            if (bid) {
                bid.selectionTime = new Date(agentStatus.date).getTime();
            }
        }
        return bid;
    }
);

export const selectNextBid = pipe(
    select(selectNextBid_),
    filter((val) => !!val),
    map((val) => val as Bid)
);

/* ******************* Supervisor View ******************* */

export const selectTotalLiveCallCount = createSelector(selectCalls, (calls: Call[]) => calls.filter((call) => CallFunctions.isLive(call.status)).length);

export const selectHistoricalCallCount = createSelector(selectHistoricalCalls, (calls) => calls.length);

export const selectUnreleasedCallCount = createSelector(selectUnreleasedCalls, (calls) => calls.length);

export const selectAgentTableData = createSelector(selectAllActiveCalls, selectFilteredAgents, selectActiveAgentTableFilters, selectClusterConfigurationMap, (calls, agents, filters, clusterMap) =>
    agents
        .filter((agent) => !(filters.includes(agent.agentStatus) || filters.includes(agent.preferredRole?.toLowerCase())))
        .map((agent) => {
            let call = calls.find((call) => CallFunctions.isActiveParticipant(call, agent.username));
            let agentCalls = calls.filter((call) => call.participants[agent.username] && !call.participants[agent.username].leftOn).length;
            let status = call ? CallFunctions.getCallStatus(call , agent): undefined;
            let callDuration = call ? call.createdTimestamp : 0;
            let joinedTime = call ? call.participants[agent.username].joinedOn : 0;
            let queue = call
                ? [...call.queueJoins]
                      ?.sort(SortFunctions.joinedOnSort)
                      .reverse()
                      .map((queue) => queue.queueName)[0]
                : undefined;
            return {
                ...agent,
                callCount: agentCalls,
                priorityCallStatus: status,
                priorityCallDuration: callDuration,
                priorityCallAgentJoinedOn: joinedTime,
                priorityQueueName: queue,
                clusterLabel: clusterMap[agent.clusterName].clusterLabel
            } as AgentTableData;
        })
);

export const selectMyRecentLiveCalls = createSelector(selectLiveCalls, selectUsername, (calls, username) =>
    calls.filter((call) => CallFunctions.isInactiveParticipant(call, username) && !CallFunctions.isHeld(call, username))
);

export const selectAnalyticsRecentCallState = createSelector(
    selectCallState,
    (state) => state.analyticsRecent
);

export const selectAnalyticsRecentCalls = createSelector(
    selectAnalyticsRecentCallState,
    selectAllAnalyticsRecentCalls,
);

export const selectAllRecentCalls = createSelector(
    selectMyRecentLiveCalls,
    selectHistoricalCalls,
    selectAnalyticsRecentCalls,
    selectUsername,
    (live, historical, recent, username) => {
        const calls = [...live, ...historical];
        recent.forEach((call) => calls.push({
            clusterName: call.clusterName,
            uuid: call.chsCallId,
            callQueues: [call.queue],
            type: call.direction === 'Outgoing' ? 'OUTBOUND' : 'INBOUND',
            queueJoins: call.queue && call.queue.length ? [
                {
                    queueName: call.queue,
                    leftOn: call.callEndDateTime
                }
            ] : [],
            participants: {
                [username]: {
                    joinedOn: call.agentStartDateTime,
                    leftOn: call.agentEndDateTime
                }
            },
            callback: call.callbackNumber,
            priority: { name: call.priority },
            createdTimestamp: call.callStartDateTime,
            releasedOn: call.callEndDateTime,
            status: 'HISTORICAL'
        } as unknown as Call));
        return calls;
    }
);

export const selectRecentCallsMap = createSelector(
    selectAllRecentCalls,
    (calls) => calls.reduce<Dictionary<Call>>((arr, curr) => ({ [curr.uuid]: curr, ...arr }), {})
);

export const selectSlicedRecentCalls = createSelector(
    selectAllRecentCalls,
    selectLockedCallIds,
    selectUiConfiguration,
    (recentCalls, lockedCallIds, { recentCallLimit }) => {
        const lockedCalls = recentCalls.filter((call) => lockedCallIds.includes(call.uuid));
        const unlockedCalls = recentCalls.filter((call) => !lockedCallIds.includes(call.uuid));

        // Ensure we retain locked calls and drop off unlocked calls
        const allowedUnlockedCalls = unlockedCalls.slice(0, recentCallLimit);
        const allowedCalls = [...lockedCalls, ...allowedUnlockedCalls].sort(SortFunctions.newestReleasedSort);

        return allowedCalls;
    }
);

export const selectRecentCallCount = createSelector(
    selectSlicedRecentCalls,
    (calls) => calls.length
);

export const selectFilteredRecentCalls = createSelector(
    selectSlicedRecentCalls,
    selectActiveRecentCallFilters,
    selectLockedCallIds,
    (recentCalls, filters, lockedCallIds) =>
        CallFunctions.filterRecentCalls(recentCalls, filters, lockedCallIds)
);

export const selectCallTableCalls = createSelector(selectLiveCalls, selectUserCallQueueNames,  (calls: Call[], userCallQueues) =>
    calls.filter((call) => userCallQueues.some((queue) => call.callQueues.includes(queue)))
);

export const selectSupervisorCallTableCalls = createSelector(selectLiveCalls, selectSupervisedUserCallQueueNames,  (calls: Call[], supervisedCallQueues) =>
    calls.filter((call) => supervisedCallQueues.some((queue) => call.callQueues.includes(queue)))
);

export const selectSupervisorFilteredCalls = createSelector(selectSupervisorCallTableCalls, selectActiveCallTableFilters, (calls, callFilters) =>
    CallFunctions.filterCallsByTableFilter(calls, callFilters) as CallTableData[]
);

export const selectSupervisorScopedCallCount = createSelector(selectSupervisorCallTableCalls, (calls) => calls.length);
export const selectSupervisorFilteredCallCount = createSelector(selectSupervisorFilteredCalls, (calls) => calls.length);

export const selectFilteredCallTableCalls = createSelector(
    selectCallTableCalls,
    selectActiveStandaloneCallTableFilters, (calls, filters) => CallFunctions.filterCallsByTableFilter(calls, filters) as CallTableData[]
);

export const selectIsObserving = createSelector(selectObservedUsername, (observedUsername) => Boolean(observedUsername));

export const selectObservedUser = createSelector(selectUserMap, selectObservedUsername, (users, observedUsername) =>
    users[observedUsername]
);

export const selectObservedUserCalls = createSelector(selectObservedUsername, selectLiveCalls, (observedUsername, calls) =>
    !observedUsername ? [] : calls.filter((c) => CallFunctions.isActiveParticipant(c, observedUsername) || CallFunctions.isHeldParticipant(c, observedUsername)).sort(SortFunctions.textFirstSort)
);

export const selectObservedUserCallCount = createSelector(selectObservedUserCalls, (observedCalls) => observedCalls.length);

export const selectObservedUserHasCall = createSelector(selectObservedUserCallCount, (count) => Boolean(count));

export const selectNextCallToObserve = createSelectorFactory<object, Call>(callMemoize)(selectIsObservationStateActive, selectActiveVoiceCall, selectUsername, selectObservedUsername, selectObservedUserCalls,
    (isObservationStateActive: boolean, activeVoiceCall: Call, username: string, observedUsername: string, observedUserCalls: Call[]) =>
        !isObservationStateActive ? undefined :
            observedUserCalls.find((call) =>
                (CallFunctions.isActiveParticipant(activeVoiceCall, username) && !CallFunctions.isSilentMonitoring(activeVoiceCall, username)) ||
                !CallFunctions.isActiveParticipant(call, username) &&
                !CallFunctions.isSilentMonitoring(call, username) &&
                CallFunctions.isConnected(call.status) &&
                CallFunctions.isActiveParticipant(call, observedUsername))
);

export const selectNextObservedCallToRelease = createSelector(selectUsername, selectObservedUsername, selectObservedUserCalls, (username, observedUsername, calls) =>
    calls.find((call) =>
        CallFunctions.isSilentMonitoring(call, username) &&
        CallFunctions.isInactiveParticipant(call, observedUsername) &&
        !CallFunctions.isHeld(call, observedUsername))
);

export const selectSelectedObservedCall = createSelector(selectSelectedCallId, selectObservedUserCalls, (selectedCallId, observedCalls) =>
    observedCalls.find((call) => call.uuid ===  selectedCallId));

export const selectIsDirectoryDisabled = createSelector(
    selectSelectedCall,
    selectUsername,
    selectIsPhoneRegistered,
    selectObservedUsername,
    (call, username, registered, observedUsername) => !registered || Boolean(call && !CallFunctions.isActiveParticipant(call, username) || !!observedUsername)
);

export const selectAutoLogoffInitiated = createSelector(selectAutoLogoff, selectMyActiveCalls, (autoLogoffRequest, calls) =>
    calls.length ? undefined : autoLogoffRequest
);
export const selectLogoffDisabled = createSelector(selectMyActiveCalls, (calls) => Boolean(calls.length));

export const selectAllAnalyticsCallHistory = createSelectorFactory<object, AnalyticsHistoricalCall[]>(arrayFilterMemoize)(
    selectAnalyticsHistoricalCallState,
    selectAllAnalyticsHistoricalCalls
);
export const selectAnalyticsCallHistory = (callback: string) =>
    createSelector(selectAllAnalyticsCallHistory, selectLocationDisplayTemplate_, selectHistoryDepth, (history, template, maxLength) =>
        history
            .filter((record) => record.referenceKey === callback)
            //@ts-ignore
            .map((record) => ({ ...record, locationString: Handlebars.templates[template?.concise](record?.location?.location) ?? 'No Location' }))
            .slice(0, maxLength)
    );

const selectCallbackMap = createSelector(
    selectCallsMap,
    selectStandardLocationMap,
    selectUsername,
    (callMap, locationMap, username) =>
        // @ts-ignore
        Object.fromEntries(
            Object.entries(callMap).map(([callId, call]) => [
                callId,
                call && CallbackUtil.getCallback(
                    call, locationMap[call.uuid], username)
            ])
        ) as { [id: string]: string }
);

const selectHistoricalCallbackMap = createSelector(
    selectHistoricalCallsMap,
    selectRecentCallsMap,
    selectStandardLocationMap,
    selectUsername,
    (callMap, recentCallMap, locationMap, username) =>
        Object.fromEntries(
            Object.entries({ ...callMap, ...recentCallMap }).map(([callId, call]) => [
                callId,
                call && CallbackUtil.getCallback(
                    call, locationMap[call.uuid], username)
            ])
        ) as { [id: string]: string }
);

export const selectAllCallbackMap = createSelector(
    selectCallbackMap,
    selectHistoricalCallbackMap,
    (callbackMap, historicalCallbackMap) => ({ ...callbackMap, ...historicalCallbackMap })
);

export const selectSelectedCallsStandardLocation = createSelector(selectSelectedCallId, selectStandardLocationMap, (callId, locationMap) =>
    callId ? locationMap[callId]?.location : undefined
);

export const selectStandardLocationAdrSpeedDials = createSelector(selectSelectedCallsStandardLocation, selectServiceRespondersUrnMap, (location, serviceRespondersMap) => {
    return location?.emergencyCallDataRefs ? location.emergencyCallDataRefs.map((emergencyCallDataRef) =>
            emergencyCallDataRef.uris ? emergencyCallDataRef.uris
                .filter((uri) => MediaService.isSipUri(uri) || isValidPhoneNumber(uri, 'US'))
                .map((uri) => {
                    let serviceResponder = serviceRespondersMap[emergencyCallDataRef.ref];
                    return {
                        uuid: v4(),
                        type: DirectoryItemType.ADR_BUTTON,
                        name: `${emergencyCallDataRef.type} ${emergencyCallDataRef.purpose}`,
                        color: serviceResponder ? serviceResponder.color : 'grey',
                        uri: uri,
                        icon: serviceResponder ? serviceResponder.icon : 'ic_status_unknown'
                    } as AdrSpeedDial;
                }) : []).flat()
        : [];
});

export const selectHasSuggestedSpeedDials = createSelector(selectStandardLocationAdrSpeedDials, (adr) => Boolean(adr.length));

export const selectRebidDisabled = (callId: string) =>
    createSelector(selectCallsMap, (callMap) => Boolean(callMap[callId]?.type === 'OUTBOUND' || callMap[callId]?.type === 'INTERNAL'));

export const selectReportDisabled = (callId: string) =>
    createSelector(selectRebidDisabled(callId), (rebidDisabled) => {
        return rebidDisabled;
    });

export const selectCopyDisabled = (callId: string) =>
    createSelector(
        selectRebidDisabled(callId),
        selectStandardLocationEvent(callId),
        selectEnhancedLocationEvent(callId),
        (rebidDisabled, standardLocation, enhancedLocation) => {
            return rebidDisabled || !(standardLocation || enhancedLocation);
        }
    );

/** CCHub call state dependant selectors **/

const _selectCcHubOnCallState = createSelector(selectHasActiveVoiceCall, selectCCHubConnectedAndInitialized, (onCall, ccHubOn) =>
    ccHubOn ? onCall : undefined
);

export const selectCcHubHeadsetMuteControlsDisabled = createSelector(_selectCcHubOnCallState, (onCall) => !Boolean(onCall));

export const selectLlrState = pipe(
    select(_selectCcHubOnCallState),
    filter((val) => val !== undefined),
    map((val) => val)
);

export const selectOffHookState = pipe(
    select(_selectCcHubOnCallState),
    filter((val) => val !== undefined),
    map((val) => val)
);

export const selectBargedIn = (callId: string) => createSelector(
    selectCallsMap,
    selectUsername,
    selectObservationState,
    (callMap, username: string, state) =>
        state.bargedIn === callId && CallFunctions.isActiveParticipant(callMap[callId], username)
);

export const selectTelephonyTransmitState = pipe(
    select(_selectCcHubOnCallState),
    filter((val) => val !== undefined),
    map((val) => val)
);

export const selectCcHubInputOnCall = (peripheral: string) =>
    createSelector(selectCcHubCallAudioInputConnected(peripheral), _selectCcHubOnCallState, (connected, onCall) => {
        return connected && onCall;
    });

export const selectCcHubOutputOnCall = (peripheral: string) =>
    createSelector(selectCcHubAudioOutputConnected(peripheral), _selectCcHubOnCallState, (connected, onCall) => {
        return connected && onCall;
    });

export const selectCallback = (callId: string) => createSelector(
    selectAllCallbackMap,
    (callbackMap) => callbackMap && callbackMap[callId]
);
