/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { v4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class SessionGuardService {

    private readonly SESSION_KEY = 'MSI_CALL_HANDLING';
    private readonly SESSION_ID: string;

    constructor() {
        this.SESSION_ID = v4();
        this.register();
    }

    private register() {
        console.debug('Checking for other call handling instances.');
        if (!localStorage.getItem(this.SESSION_KEY)) {
            console.debug(`No other call handling instances found, setting session id: ${this.SESSION_ID}`);
            localStorage.setItem(this.SESSION_KEY, this.SESSION_ID);
            window.addEventListener('beforeunload', () => this.clear());
        } else {
            console.warn(`Another Call handling instances detected. ${localStorage.getItem(this.SESSION_KEY)}`);
        }
    }

    private clear() {
        let registeredSession = localStorage.getItem(this.SESSION_KEY);
        if (registeredSession === this.SESSION_ID) {
            console.debug(`Clearing call handling instance. session: ${registeredSession}`);
            localStorage.removeItem(this.SESSION_KEY);
        }
    }

    public canInitialize() {
        return Boolean(localStorage.getItem(this.SESSION_KEY) === this.SESSION_ID);
    }
}
