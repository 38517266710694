/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import {
    LocationState,
    selectAllEnhancedLocationsReducer,
    selectAllStandardLocationsReducer,
    selectEnhancedLocationEntities,
    selectEnhancedLocationState,
    selectStandardLocationEntities,
    selectStandardLocationState
} from './location.reducer';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { selectCallRedialUuid } from '../../call/+state/call.selectors';
import { ServiceResponderUrn } from '../model/service-responders';

export const LOCATION_FEATURE = 'location';

export const selectLocationState = createFeatureSelector<LocationState>(LOCATION_FEATURE);
export const selectLocationEffectInitialized = createSelector(selectLocationState, (state) => state.initialized);

export const selectLocationStateStandard = createSelector(selectLocationState, selectStandardLocationState);
export const selectLocationStateEnhanced = createSelector(selectLocationState, selectEnhancedLocationState);

export const selectStandardLocationMap = createSelector(selectLocationStateStandard, selectStandardLocationEntities);
export const selectEnhancedLocationMap = createSelector(selectLocationStateEnhanced, selectEnhancedLocationEntities);

export const selectAllStandardLocations = createSelector(selectLocationStateStandard, selectAllStandardLocationsReducer);
export const selectAllEnhancedLocations = createSelector(selectLocationStateEnhanced, selectAllEnhancedLocationsReducer);

export const selectStandardLocation = (callId: string) =>
    createSelector(selectStandardLocationMap, selectCallRedialUuid(callId), (locationMap, redialUuid) =>
        locationMap[callId] ? locationMap[callId].location : locationMap[redialUuid]?.location
    );
export const selectEnhancedLocation = (callId: string) =>
    createSelector(selectEnhancedLocationMap, selectCallRedialUuid(callId), (locationMap, redialUuid) =>
        locationMap[callId] ? locationMap[callId].location : locationMap[redialUuid]?.location
    );

export const selectStandardLocationEvent = (callId: string) =>
    createSelector(selectStandardLocationMap, selectCallRedialUuid(callId), (locationMap, redialUuid) =>
        locationMap[callId] ? locationMap[callId] : locationMap[redialUuid]
    );
export const selectEnhancedLocationEvent = (callId: string) =>
    createSelector(selectEnhancedLocationMap, selectCallRedialUuid(callId), (locationMap, redialUuid) =>
        locationMap[callId] ? locationMap[callId] : locationMap[redialUuid]
    );

export const selectIsWireless = (callId: string) =>
    createSelector(selectStandardLocation(callId), (standardLocation) => Boolean(standardLocation?.geoPoints.some((geoPoint) => geoPoint.certainty)));

export const selectLocationDisplayTemplate_ = createSelector(selectLocationState, (state) => state.layoutConfiguration);
export const selectLocationDisplayTemplate = pipe(
    select(selectLocationDisplayTemplate_),
    filter((val) => !!val),
    map((val) => val as LocationDisplayTemplate)
);

export const selectManualLocationNumber = createSelector(selectLocationState, (state) => state.manualLocationNumber);
export const selectManualLocation = createSelector(selectLocationState, (state) => state.manualLocationEvent);
export const selectManualLocationPending = createSelector(selectLocationState, (state) => state.manualLocationPending);
export const selectIsManualLocationWireless = createSelector(selectManualLocation, (location) =>
    Boolean(location?.location?.geoPoints.some((geoPoint) => geoPoint.certainty))
);

export const selectServiceResponders = createSelector(selectLocationState, (state) => state.serviceResponders);

export const selectServiceRespondersUrnMap = createSelector(
    selectServiceResponders,
    (serviceResponders) =>
        serviceResponders?.serviceResponderUrns.reduce<{ [key: string]: ServiceResponderUrn }>(
            (map, serviceResponderUrn) => ({ ...map, [serviceResponderUrn.urn]: serviceResponderUrn }),
            {}
        )
);
