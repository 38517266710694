import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterMenuCategory } from '../../../supervisor/models/checkbox-object';
import { DropdownComponent, DropdownXPosition } from '@msi/cobalt';
import { Store } from '@ngrx/store';
import { updateFilters } from 'src/app/settings/+state/settings.actions';
import { applicationFilter, FilterState } from '../../../settings/model/settings.model';

@Component({
    selector: 'msi-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilterMenuComponent implements OnChanges {
    @ViewChild(DropdownComponent) componentRef!: DropdownComponent;

    @Input()
    filterType!: keyof FilterState;

    @Input()
    filters: FilterMenuCategory<applicationFilter>[] = [];

    @Input()
    xPosition: DropdownXPosition = 'right';

    @Input()
    width = 400;

    @Input()
    size = 36;

    @Input()
    activeFilters: (applicationFilter | string)[] = [];

    public pendingActiveFilters: string[] = [];

    public changes = 0;

    constructor(private store: Store) {}

    ngOnChanges() {
        this.pendingActiveFilters = [...this.activeFilters];
        this.changes = 0;
        this.filters.forEach((category) => {
            const activeCount = category.filters.filter((f) => !this.pendingActiveFilters.includes(f.key)).length;
            if (category.filters.length !== activeCount) {
                this.changes++;
            }
        });
    }

    public close() {
        this.componentRef.close();
    }

    public cancel() {
        this.componentRef.close();
    }

    public apply() {
        this.store.dispatch(
            updateFilters({
                filterKey: this.filterType,
                filters: this.pendingActiveFilters
            })
        );
        this.componentRef.close();
    }

    public reset() {
        this.pendingActiveFilters = [...this.activeFilters];
    }

    public updateFilter(checked: boolean, key: string) {
        if (!this.pendingActiveFilters.includes(key)) {
            this.pendingActiveFilters.push(key);
        } else {
            this.pendingActiveFilters.splice(this.pendingActiveFilters.indexOf(key), 1);
        }
    }
}
