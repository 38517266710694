import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {QueueMetricObj} from './metrics.model';
import * as MetricsActions from './metrics.actions';

export const METRICS_FEATURE = 'metrics';

export const adapter: EntityAdapter<QueueMetricObj> = createEntityAdapter<QueueMetricObj>({
    selectId: (metrics) => metrics.uuid
});

export const initialState: EntityState<QueueMetricObj> = adapter.getInitialState({});

export const reducer = createReducer(
    initialState,
    on(MetricsActions.updateMetrics,
        (state, action) => adapter.setAll(action.metrics, state)
    )
);

export const {
    selectAll,
} = adapter.getSelectors();


