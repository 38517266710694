/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createReducer, on } from '@ngrx/store';
import {
    fetchLocationLayoutSuccess,
    fetchServiceRespondersSuccess,
    locationEffectsInitialized,
    manualQuery,
    manualQueryFail,
    manualQuerySuccess,
    purgeLocation,
    updateEnhancedLocation,
    updateStandardLocation
} from './location.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LocationEvent } from '../model/location';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { ServiceResponders } from '../model/service-responders';

export interface LocationState {
    initialized: boolean;
    standard: StandardLocationState;
    enhanced: EnhancedLocationState;
    layoutConfiguration: LocationDisplayTemplate | undefined;
    manualLocationNumber: string | undefined;
    manualLocationEvent: LocationEvent | undefined;
    manualLocationPending: boolean;
    serviceResponders: ServiceResponders | undefined;
}

export interface StandardLocationState extends EntityState<LocationEvent> {}

export interface EnhancedLocationState extends EntityState<LocationEvent> {}

export const standardLocationAdapter: EntityAdapter<LocationEvent> = createEntityAdapter<LocationEvent>({
    selectId: (locationEvent: LocationEvent) => locationEvent.callId
});

export const enhancedLocationAdapter: EntityAdapter<LocationEvent> = createEntityAdapter<LocationEvent>({
    selectId: (locationEvent: LocationEvent) => locationEvent.callId
});

const initialStandardLocationState: StandardLocationState = standardLocationAdapter.getInitialState({});

const initialEnhancedLocationState: EnhancedLocationState = enhancedLocationAdapter.getInitialState({});

const initialState: LocationState = {
    initialized: false,
    standard: initialStandardLocationState,
    enhanced: initialEnhancedLocationState,
    layoutConfiguration: undefined,
    manualLocationNumber: undefined,
    manualLocationEvent: undefined,
    manualLocationPending: false,
    serviceResponders: undefined
};

export const locationReducer = createReducer(
    initialState,
    on(locationEffectsInitialized, (state): LocationState => {
        return { ...state, initialized: true };
    }),
    on(updateStandardLocation, (state, { locationEvent }) => {
        let existingEntity = state.standard.entities[locationEvent.callId];
        if (!existingEntity || existingEntity.createdDateTime <= locationEvent.createdDateTime) {
            return { ...state, standard: standardLocationAdapter.setOne(locationEvent, state.standard) };
        }
        return state;
    }),
    on(updateEnhancedLocation, (state, { locationEvent }) => {
        let existingEntity = state.enhanced.entities[locationEvent.callId];
        if (!existingEntity || existingEntity.createdDateTime <= locationEvent.createdDateTime) {
            return { ...state, enhanced: enhancedLocationAdapter.setOne(locationEvent, state.enhanced) };
        }
        return state;
    }),
    on(purgeLocation, (state, { uuid }) => {
        return {
            ...state,
            standard: standardLocationAdapter.removeOne(uuid, state.standard),
            enhanced: enhancedLocationAdapter.removeOne(uuid, state.enhanced)
        };
    }),
    on(fetchLocationLayoutSuccess, (state, { response }): LocationState => {
        return { ...state, layoutConfiguration: response };
    }),
    on(manualQuery, (state, { number }): LocationState => {
        return { ...state, manualLocationNumber: number, manualLocationEvent: undefined, manualLocationPending: true };
    }),
    on(manualQuerySuccess, (state, { number, locationEvent }): LocationState => {
        return { ...state, manualLocationNumber: number, manualLocationEvent: locationEvent, manualLocationPending: false };
    }),
    on(manualQueryFail, (state, { number }): LocationState => {
        return { ...state, manualLocationNumber: number, manualLocationEvent: undefined, manualLocationPending: false };
    }),
    on(fetchServiceRespondersSuccess, (state, { response }): LocationState => {
        return { ...state, serviceResponders: response };
    })
);

export const selectStandardLocationState = (state: LocationState) => state.standard;
export const selectEnhancedLocationState = (state: LocationState) => state.enhanced;

export const { selectEntities: selectStandardLocationEntities, selectAll: selectAllStandardLocationsReducer } = standardLocationAdapter.getSelectors();

export const { selectEntities: selectEnhancedLocationEntities, selectAll: selectAllEnhancedLocationsReducer } = enhancedLocationAdapter.getSelectors();
