/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SortFunctions } from '../util/sort-functions';
import { Call } from 'CalltakingCoreApi';
import {
    bargeIn,
    closeCall,
    deleteCall,
    endObserveAgent,
    fetchAnalyticsHistoricalCallsSuccess,
    fetchCallsSuccess, fetchRecentCallsSuccess,
    holdSuccess,
    linkedCallUpdate,
    lockCall,
    newCall,
    observeAgent,
    openCall,
    purgeAnalyticsHistoricalCall,
    purgeHistoricalCall,
    recordHistoricalCall,
    requestActiveCall,
    requestHoldSuccess,
    setObservationState,
    silentMonitorSuccess,
    toggleChat,
    unlockCall,
    updateActiveCall,
    updateCall,
    updateCallSubscriptions,
    updateSelectedCall
} from './call.actions';
import { AnalyticsHistoricalCall, AnalyticsRecentCall } from '../model/analytics-historical-call';

export type ResumeObserveMode = 'onRelease' | 'manual';
export type ObservationState = 'inactive' | 'active' | 'suspended';
export interface ActiveObservationState {
    state: ObservationState;
    observedUser: string | undefined;
    bargedIn: string | undefined; // callId
}

export interface CallState {
    subscriptions: { [topic: string]: { [receipt: string]: boolean } };
    local: LocalCallState;
    historical: LocalHistoricalCallState;
    remote: RemoteCallState;
    remoteHistorical: RemoteHistoricalCallState;
    analyticsHistorical: AnalyticsCallHistoryState;
    analyticsRecent: AnalyticsRecentCallState;
    pendingActiveCallId: string | undefined;
    pendingHeldCall: string | undefined;
    heldCallCallback: Action | undefined;
    activeCallId: string | undefined;
    selectedCallId: string | undefined;
    chatOpen: boolean;
    deletedCache: {};
    lockedCalls: string[];
    openedCalls: string[];
    observationState: ActiveObservationState;
}

export interface LocalCallState extends EntityState<Call> {}

export interface LocalHistoricalCallState extends EntityState<Call> {}

export interface RemoteHistoricalCallState extends EntityState<Call> {}

export interface RemoteCallState extends EntityState<Call> {}

export interface AnalyticsCallHistoryState extends EntityState<AnalyticsHistoricalCall> {}

export interface AnalyticsRecentCallState extends EntityState<AnalyticsRecentCall> {}

export const localAdapter: EntityAdapter<Call> = createEntityAdapter<Call>({
    selectId: (call: Call) => call.uuid,
    sortComparer: SortFunctions.defaultCallSort
});

export const localHistoricalAdapter: EntityAdapter<Call> = createEntityAdapter<Call>({
    selectId: (call: Call) => call.uuid,
    sortComparer: SortFunctions.newestReleasedSort
});

export const remoteHistoricalAdapter: EntityAdapter<Call> = createEntityAdapter<Call>({
    selectId: (call: Call) => call.uuid,
    sortComparer: SortFunctions.newestReleasedSort
});

export const remoteAdapter: EntityAdapter<Call> = createEntityAdapter<Call>({
    selectId: (call: Call) => call.uuid
});

export const analyticsHistoricalAdapter: EntityAdapter<AnalyticsHistoricalCall> = createEntityAdapter<AnalyticsHistoricalCall>({
    selectId: (record: AnalyticsHistoricalCall) => record.callId,
    sortComparer: SortFunctions.newestEndedAnalyticsSort
});

export const analyticsRecentAdapter = createEntityAdapter<AnalyticsRecentCall>({
    selectId: ((call) => call.chsCallId),
    sortComparer: SortFunctions.newestEndedAnalyticsRecentSort
});

const initialLocalState: LocalCallState = localAdapter.getInitialState({});

const initialHistoricalState: LocalCallState = localAdapter.getInitialState({});

const initialRemoteState: RemoteCallState = localAdapter.getInitialState({});

const initialRemoteHistoricalState: RemoteHistoricalCallState = localAdapter.getInitialState({});

const initialAnalyticsHistoricalState: AnalyticsCallHistoryState = analyticsHistoricalAdapter.getInitialState({});

const initialAnalyticsRecentState: AnalyticsRecentCallState = analyticsRecentAdapter.getInitialState({});

const initialState: CallState = {
    subscriptions: {},
    local: initialLocalState,
    historical: initialHistoricalState,
    remote: initialRemoteState,
    remoteHistorical: initialRemoteHistoricalState,
    analyticsHistorical: initialAnalyticsHistoricalState,
    analyticsRecent: initialAnalyticsRecentState,
    pendingActiveCallId: undefined,
    pendingHeldCall: undefined,
    heldCallCallback: undefined,
    activeCallId: undefined,
    selectedCallId: undefined,
    chatOpen: false,
    deletedCache: {},
    lockedCalls: [],
    openedCalls: [],
    observationState: {
        observedUser: undefined,
        state: 'inactive',
        bargedIn: undefined
    }
};

export const callReducer = createReducer(
    initialState,
    on(updateCallSubscriptions, (state, { subscriptions }): CallState => {
        return { ...state, subscriptions: subscriptions };
    }),
    on(newCall, updateCall, (state, { call }) => {
        let existingEntity = state.local.entities[call.uuid];
        if (!existingEntity || existingEntity.updatedTimestamp <= call.updatedTimestamp) {
            let pendingActiveCallId = state.pendingActiveCallId && call.uuid === state.pendingActiveCallId ? undefined : state.pendingActiveCallId;
            let activeCallId = state.pendingActiveCallId && call.uuid === state.pendingActiveCallId ? state.pendingActiveCallId : state.activeCallId;
            return { ...state, local: localAdapter.setOne(call, state.local), pendingActiveCallId: pendingActiveCallId, activeCallId: activeCallId };
        }
        return state;
    }),
    on(deleteCall, (state, { call }) => {
        let existingEntity = state.local.entities[call.uuid] as Call;
        if (existingEntity) {
            return {
                ...state,
                local: localAdapter.removeOne(call.uuid, state.local),
                remote: remoteAdapter.removeMany((call) => Object.values(existingEntity.linkedAgencies)?.includes(call.uuid), state.remote)
            };
        }
        return state;
    }),
    on(recordHistoricalCall, (state, { call }) => {
        let activeCallId = call.uuid === state.activeCallId ? undefined : state.activeCallId;
        let linkedRemoteCallRecords = Object.values(call.linkedAgencies)
            .filter((remoteCallId) => state.remote.entities[remoteCallId])
            .map((remoteCallId) => state.remote.entities[remoteCallId] as Call);
        return {
            ...state,
            activeCallId: activeCallId,
            historical: localHistoricalAdapter.upsertOne(call, state.historical),
            remote: remoteAdapter.removeMany(
                linkedRemoteCallRecords.map((call) => call.uuid),
                state.remote
            ),
            remoteHistorical: remoteHistoricalAdapter.upsertMany(linkedRemoteCallRecords, state.remoteHistorical)
        };
    }),
    on(purgeHistoricalCall, (state, { uuid }) => {
        let localCallBeingPurged = state.historical.entities[uuid] as Call;
        return {
            ...state,
            historical: localHistoricalAdapter.removeOne(uuid, state.historical),
            remoteHistorical: remoteHistoricalAdapter.removeMany(
                (call) => Object.values(localCallBeingPurged.linkedAgencies)?.includes(call.uuid),
                state.remoteHistorical
            )
        };
    }),
    on(purgeAnalyticsHistoricalCall, (state, { uuid }) => {
        return { ...state, analyticsHistorical: analyticsHistoricalAdapter.removeMany((record) => record.ctcCallId === uuid, state.analyticsHistorical) };
    }),
    on(fetchCallsSuccess, (state, { clusterName, calls }) => {
        let newCallsMap = Object.fromEntries(calls.map((call) => [call.uuid, call]));
        let callsToRemove = Object.values(state.local.entities)
            .filter((existingCall) => existingCall.clusterName === clusterName &&
                (!newCallsMap[existingCall.uuid] || newCallsMap[existingCall.uuid].updatedTimestamp > existingCall.updatedTimestamp))
            .map((call) => call.uuid);
        return { ...state, local: localAdapter.upsertMany(calls, localAdapter.removeMany(callsToRemove, state.local)) };
    }),
    on(fetchAnalyticsHistoricalCallsSuccess, (state, { referenceKey, calls }) => {
        let analyticsHistoricalCalls = calls.map((record) => ({ ...record, referenceKey: referenceKey }));
        return { ...state, analyticsHistorical: analyticsHistoricalAdapter.upsertMany(analyticsHistoricalCalls, state.analyticsHistorical) };
    }),
    on(fetchRecentCallsSuccess, (state, { calls }): CallState => {
        return { ...state, analyticsRecent: analyticsRecentAdapter.upsertMany(calls, state.analyticsRecent),
            lockedCalls: [...state.lockedCalls, ...calls.filter(((c) => Boolean(c.lock))).map((c) => c.chsCallId)] };
    }),
    on(linkedCallUpdate, (state, { call }) => {
        let existingEntity = state.remote.entities[call.uuid] as Call;
        let existingHistoricalEntity = state.remoteHistorical.entities[call.uuid] as Call;
        if (existingHistoricalEntity && existingHistoricalEntity.updatedTimestamp <= call.updatedTimestamp) {
            return { ...state, remoteHistorical: remoteHistoricalAdapter.upsertOne(call, state.remoteHistorical) };
        } else if (!existingEntity || (existingEntity && existingEntity.updatedTimestamp <= call.updatedTimestamp)) {
            return { ...state, remote: remoteAdapter.upsertOne(call, state.remote) };
        }
        return state;
    }),
    on(requestActiveCall, (state, { callId }): CallState => {
        return { ...state, pendingActiveCallId: callId };
    }),
    on(updateActiveCall, (state, { callId }): CallState => {
        return { ...state, activeCallId: callId };
    }),
    on(requestHoldSuccess, (state, { callId, callback }): CallState => {
        return { ...state, pendingHeldCall: callId, heldCallCallback: callback };
    }),
    on(holdSuccess, (state): CallState => {
        return { ...state, pendingHeldCall: undefined, heldCallCallback: undefined };
    }),
    on(updateSelectedCall, (state, { callId }): CallState => {
        return { ...state, selectedCallId: callId };
    }),
    on(toggleChat, (state, { open }): CallState => {
        return { ...state, chatOpen: open };
    }),
    on(observeAgent, (state, { username }): CallState => {
        return {
            ...state,
            observationState: {
                observedUser: username,
                state: 'active',
                bargedIn: undefined
            }
        };
    }),
    on(endObserveAgent, (state): CallState => {
        return {
            ...state,
            observationState: {
                observedUser: undefined,
                state: 'inactive',
                bargedIn: undefined
            }
        };
    }),
    on(lockCall, (state, { callId }): CallState => {
        return { ...state, lockedCalls: [...state.lockedCalls, callId] };
    }),
    on(unlockCall, (state, { callId }): CallState => {
        const temp = [...state.lockedCalls];
        const foundIndex = temp.indexOf(callId);
        if (foundIndex > -1) {
            temp.splice(foundIndex, 1);
        }
        return { ...state, lockedCalls: temp };
    }),
    on(closeCall, (state, { callId }) => {
        const temp = [...state.openedCalls];
        const foundIndex = temp.indexOf(callId);
        if (foundIndex > -1) {
            temp.splice(foundIndex, 1);
        }
        return { ...state, openedCalls: temp };
    }),
    on(openCall, (state, { callId }): CallState => {
        return state.openedCalls.includes(callId) ?
            { ...state } :
            { ...state, openedCalls: [...state.openedCalls, callId] };
    }),
    on(
        bargeIn,
        (state, { callId }): CallState => ({
            ...state,
            observationState: {
                ...state.observationState,
                bargedIn: callId
            }
        })
    ),
    on(
        silentMonitorSuccess,
        (state): CallState => ({
            ...state,
            observationState: {
                ...state.observationState,
                bargedIn: undefined
            }
        })
    ),
    on(setObservationState, (state, { observationState }): CallState => {
        return {
            ...state,
            observationState: {
                ...state.observationState,
                state: observationState
            }
        };
    })
);

export const localCallState = (state: CallState) => state.local;
export const localHistoricalCallState = (state: CallState) => state.historical;
export const remoteCallState = (state: CallState) => state.remote;
export const remoteHistoricalCallState = (state: CallState) => state.remoteHistorical;
export const analyticsHistoricalCallState = (state: CallState) => state.analyticsHistorical;
export const analyticsRecentCallState = (state: CallState) => state.analyticsRecent;

export const { selectAll: selectAllLocalCalls, selectEntities: selectLocalCallEntities, selectTotal: selectTotalCalls } = localAdapter.getSelectors();

export const { selectAll: selectAllHistoricalCalls, selectEntities: selectLocalHistoricalCallEntities } = localHistoricalAdapter.getSelectors();

export const { selectEntities: selectRemoteCallEntities } = remoteAdapter.getSelectors();

export const { selectEntities: selectHistoricalRemoteCallEntities } = remoteHistoricalAdapter.getSelectors();

export const { selectAll: selectAllAnalyticsHistoricalCalls, selectEntities: selectAnalyticsHistoricalEntities } = analyticsHistoricalAdapter.getSelectors();

export const { selectAll: selectAllAnalyticsRecentCalls, selectEntities: selectAnalyticsRecentCallEntities } = analyticsRecentAdapter.getSelectors();
