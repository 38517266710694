/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IrrService {
    private readonly audioContext: AudioContext;
    private readonly mixerNode: GainNode;
    private mediaElementAudioSourceNode: MediaElementAudioSourceNode;
    private readonly mediaStreamAudioDestinationNode: MediaStreamAudioDestinationNode;
    public readonly mediaStream: MediaStream;

    constructor() {
        console.debug(`Creating alert audio context`);
        this.audioContext = new AudioContext();
        this.mixerNode = this.audioContext.createGain();
        this.mediaStreamAudioDestinationNode = this.audioContext.createMediaStreamDestination();
        this.mixerNode.connect(this.mediaStreamAudioDestinationNode);
        this.mediaStream = this.mediaStreamAudioDestinationNode.stream;
        console.debug(`Acquired irr media stream: ${this.mediaStream.id}`);
    }

    public setIrrMediaElementSource(audio: HTMLAudioElement) {
        console.debug(`Routing irr audio for ${audio.src} to media stream ${this.mediaStream.id}`);
        this.mediaElementAudioSourceNode?.disconnect(this.mixerNode);
        this.mediaElementAudioSourceNode = new MediaElementAudioSourceNode(this.audioContext, { mediaElement: audio });
        this.mediaElementAudioSourceNode.connect(this.mixerNode);
        this.checkContext();
    }

    public checkContext() {
        if (this.audioContext.state === 'suspended') {
            this.audioContext
                .resume()
                .then(() => console.debug('Successfully started irr audio context'))
                .catch((err) => console.error('Unable to start irr audio context {}', err));
        }
    }
}
