import { Cluster } from 'CalltakingCoreApi';
import { UAConfiguration } from 'jssip/lib/UA';

export interface MediaConnection extends Cluster {
    configuration: UAConfiguration;
    socket: string;
    connected: boolean;
    registered: boolean;
    session: Partial<{
        id: string;
        state: SessionState;
    }>;
}

export enum SessionState {
    CREATED = 'CREATED',
    CONNECTING = 'CONNECTING',
    ACCEPTED = 'ACCEPTED',
    CONFIRMED = 'CONFIRMED',
    ENDED = 'ENDED',
    FAILED = 'FAILED'
}
