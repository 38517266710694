/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { selectAll } from './metrics.reducer';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QueueMetricObj, QueueMetricTypeMap } from "./metrics.model";
import { selectMonitoredQueueFilter, selectSupervisedQueueMap, } from "../../../settings/+state/settings.selectors";
import { EntityState } from "@ngrx/entity";
import { tableKey } from "../../../settings/model/settings.model";

export const selectMetricState = createFeatureSelector<EntityState<QueueMetricObj>>('metrics');

export const selectAllMetrics = createSelector(
    selectMetricState,
    selectAll);

export const selectFilteredMetrics = createSelector(
    selectMonitoredQueueFilter,
    selectAllMetrics,
    selectSupervisedQueueMap,
    (filteredQueues, metrics, queueMap) => metrics.filter((m) => !!queueMap[m.queueId] && !filteredQueues.includes(m.queueId))
);

export const selectFilteredMetricsLength = createSelector(
    selectFilteredMetrics,
    (metrics) => metrics.length
);

export const selectFilteredMetricQueueSummary = createSelector(
    selectFilteredMetrics,
    (metrics) => (metrics && metrics.length) ? metrics.reduce<{ [key: string]: number }>((acc, obj) => {
        for (const [key,value] of Object.entries(obj)) {

            if (QueueMetricTypeMap[key] === 'COUNTER') {
                // Add values if counter

                acc[key]! = (acc[key] ?? 0) + value;
            } else {

                // Take larger value if timer
                acc[key]! = (acc[key] ?? 0) > value ? acc[key] : value;
            }
        }
        return acc;
    }, {}) as unknown as QueueMetricObj : undefined
);

export const selectFilteredMetricQueueCount = createSelector(
    selectFilteredMetrics,
    (summary) => summary.length
);

export const selectFilteredCallSummaryAsList = createSelector(
    selectFilteredMetricQueueSummary,
    (summary) => [{ uuid: 'SUMMARY_CALLS' as tableKey, ...summary }] as QueueMetricObj[]
);
export const selectFilteredQueueSummaryAsList = createSelector(
    selectFilteredMetricQueueSummary,
    (summary) => [{ uuid: 'SUMMARY_QUEUES' as tableKey, ...summary }] as QueueMetricObj[]
);
