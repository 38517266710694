/*******************************************************************************
 Copyright (C)  Motorola Solutions, INC.
 All Rights Reserved.
 Motorola Solutions Confidential Restricted.
 ******************************************************************************/

import { NgModule } from '@angular/core';
import {
    AccordionItemModule,
    AccordionModule,
    AutocompleteModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    CardModule,
    CheckboxModule,
    ClockComponentModule,
    DataTableModule,
    DropdownModule,
    HeaderComponentModule,
    HeaderLeftPluginAreaComponentModule,
    HeaderRightPluginAreaComponentModule,
    IconModule,
    InlineInputModule,
    InputGroupModule,
    InputModule,
    MediaPlayerModule,
    ModalModule,
    ProgressBarModule,
    RadioButtonModule,
    SelectModule,
    SidebarModule,
    SidenavModule,
    SidepanelModule,
    SliderModule,
    SpinnerModule,
    TableModule,
    TabsModule,
    TagModule,
    ToastModule,
    ToggleModule,
    TooltipModule,
    TreeviewModule
} from '@msi/cobalt';
import { ElapsedTimeComponent } from '../core/elapsed-time/elapsed-time.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemoizedFunctionPipe } from '../call/pipes/memoized-function.pipe';
import { ToDatePipe } from '../call/pipes/to-date.pipe';
import { CountdownTimeComponent } from '../core/countdown-time/countdown-time.component';
import { EnumPipe } from '../core/pipes/enum.pipe';
import { AsDatePipe } from '../core/pipes/as-date.pipe';
import { HandlebarsTemplatePipe } from '../core/pipes/handlebars-template.pipe';
import { AsBooleanPipe } from '../core/pipes/as-boolean.pipe';
import { NgLetModule } from 'ng-let';
import { MemoizedTemplateFunctionPipe } from '../core/pipes/memoized-template-function.pipe';
import { SecondsToTimePipe } from '../supervisor/pipes/seconds-to-time.pipe';
import { FilteredAgentsPipe } from '../core/pipes/filtered-agents.pipe';
import { EnumToDescriptionPipe } from '../core/pipes/enum-to-description.pipe';
import { ConnectionStatusIconPipe } from '../core/pipes/connection-status-icon.pipe';
import { FocusFirstDirective } from '../main/directives/focus-first.directive';
import { VolumePipe } from '../core/pipes/volume.pipe';
import { FilteredQueuesPipe } from '../core/pipes/filtered-queues.pipe';
import { FilteredCallsPipe } from '../core/pipes/filtered-calls.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SortPipe } from '../core/pipes/sort.pipe';
import { AcdStatusLabelPipe } from '../user/pipes/acd-status-label.pipe';
import { UserAvatarComponent } from '../core/components/user-avatar/user-avatar.component';
import { FilterMenuComponent } from '../core/components/filter-menu/filter-menu.component';
import { ArrayIncludesPipe } from '../core/pipes/array-includes.pipe';
import { LetModule } from '@ngrx/component';
import { ObjectValuesPipe } from '../core/pipes/object-values.pipe';
import { CallTableComponent } from '../call/call-table/call-table.component';
import { AgentCallStatusPipe } from '../core/pipes/agent-call-status.pipe';
import { CallIconPipe } from '../core/pipes/call-icon.pipe';
import { CallStatusPipe } from '../core/pipes/call-status.pipe';
import { CustomTabGroupComponent } from '../core/custom-tab-group/custom-tab-group.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CallTableParticipantInfoComponent } from '../call/call-table/call-table-participant-info/call-table-participant-info.component';
import { QueuesPipe } from '../call/pipes/queues.pipe';
import { ParticipantListPipe } from '../call/pipes/participant-list.pipe';
import { ReplacePipe } from '../supervisor/pipes/replace.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { PhoneDisplayFormatPipe } from '../core/pipes/phone-display-format.pipe';
import { CustomOverlayContainerDirective } from '../core/window/directives/custom-overlay-container.directive';
import { WindowComponent } from '../core/window/window.component';
import { DebounceDirective } from '../core/directives/debounce.directive';
import { IsParticipantPipe } from '../core/pipes/is-participant.pipe';
import { IsSilentMonitoringPipe } from '../core/pipes/is-silent-monitoring.pipe';
import { IsActiveParticipantPipe } from '../core/pipes/is-active-participant.pipe';
import { CallIconHistoricalPipe } from "../core/pipes/call-icon-historical.pipe";

const declarations = [
    ElapsedTimeComponent,
    CountdownTimeComponent,
    MemoizedFunctionPipe,
    ToDatePipe,
    EnumPipe,
    AsDatePipe,
    AsBooleanPipe,
    HandlebarsTemplatePipe,
    MemoizedTemplateFunctionPipe,
    SecondsToTimePipe,
    FilteredAgentsPipe,
    FilteredQueuesPipe,
    FilteredCallsPipe,
    EnumToDescriptionPipe,
    ConnectionStatusIconPipe,
    FocusFirstDirective,
    VolumePipe,
    SortPipe,
    AcdStatusLabelPipe,
    UserAvatarComponent,
    FilterMenuComponent,
    ArrayIncludesPipe,
    ObjectValuesPipe,
    CallTableComponent,
    CallTableParticipantInfoComponent,
    AgentCallStatusPipe,
    CallIconPipe,
    CallIconHistoricalPipe,
    CallStatusPipe,
    CustomTabGroupComponent,
    QueuesPipe,
    ParticipantListPipe,
    ReplacePipe,
    PhoneDisplayFormatPipe,
    CustomOverlayContainerDirective,
    WindowComponent,
    DebounceDirective,
    IsParticipantPipe,
    IsActiveParticipantPipe,
    IsSilentMonitoringPipe
];

const modules = [
    CommonModule,
    HeaderComponentModule,
    HeaderLeftPluginAreaComponentModule,
    HeaderRightPluginAreaComponentModule,
    IconModule,
    CardModule,
    TabsModule,
    AccordionModule,
    AccordionItemModule,
    SliderModule,
    BadgeModule,
    DropdownModule,
    ToastModule,
    SidebarModule,
    SidepanelModule,
    AvatarModule,
    TagModule,
    FormsModule,
    DataTableModule,
    SelectModule,
    ReactiveFormsModule,
    AutocompleteModule,
    ProgressBarModule,
    CheckboxModule,
    InputModule,
    InputGroupModule,
    MediaPlayerModule,
    BreadcrumbModule,
    ClockComponentModule,
    InlineInputModule,
    TooltipModule,
    TableModule,
    SidenavModule,
    LetModule,
    NgLetModule,
    ToggleModule,
    RadioButtonModule,
    TreeviewModule,
    ScrollingModule,
    SpinnerModule,
    ButtonGroupModule,
    DragDropModule,
    PortalModule,
    ModalModule
];

@NgModule({
    declarations,
    imports: [...modules],
    exports: [...declarations, ...modules]
})
export class SharedModule {}
