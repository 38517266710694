<msi-icon [msiDropdownTriggerFor]="filter" class="icon-trigger msi-hover msi-focus" name="ic_filter_sm"
          [size]="size"></msi-icon>
<msi-badge id="filter-badge" *ngIf="changes > 0" [value]="changes"></msi-badge>


<msi-dropdown
    class="filter-menu"
    #filter
    [width]="width"
    [xPosition]="xPosition"
    [closeOnClick]="false"
    [backdrop]="false"
>
    <msi-dropdown-section class="filter-menu-header">
        <h3>Filters</h3>
        <button class="msi-btn msi-btn-icon filter-menu-close" (click)="close()">
            <msi-icon name="ic_remove" [size]="36"></msi-icon>
        </button>
    </msi-dropdown-section>

    <msi-dropdown-section class="filter-menu-body">
        <msi-checkbox-group class="filter-category" *ngFor="let category of filters">
            <h4 class="filter-category-name">{{ category.name }}</h4>
            <msi-checkbox #cb id="filter-{{ filter.key }}"
                          class="filter-checkbox"
                          [disabled]="filter.disabled"
                          (change)="updateFilter($event, filter.key)"
                          *ngFor="let filter of category.filters"
                          [checked]="pendingActiveFilters | arrayIncludes : filter.key : true"
                          [value]="filter.key"
            >{{ filter.displayName }}</msi-checkbox>
        </msi-checkbox-group>
    </msi-dropdown-section>

    <div class="filter-menu-footer">
        <div class="left-section">
            <button id="filter-reset-btn" class="msi-btn msi-btn-action" (click)="reset()">Reset</button>
        </div>
        <div class="right-section">
            <button id="filter-cancel-btn" class="msi-btn msi-btn-action" (click)="cancel()">Cancel</button>
            <button id="filter-apply-btn" class="msi-btn" (click)="apply()">Apply</button>
        </div>
    </div>
</msi-dropdown>
