import { ColumnThreshold } from "../../services/threshold.service";
import { metricType } from "../../../settings/model/settings.model";

export interface QueueMetricObj {
    uuid: string;
    agencyId: string;
    queueId: string;
    callsInQueue: number;
    longestCallInQueue: number;
    voiceCalls: number;
    textCalls: number;
    rttCalls: number;
    ttyCalls: number;
    callsRinging: number;
    longestCallRinging: number;
    callsWaiting: number;
    longestWaitTime: number;
    averageWaitTime: number;
    callsConnected: number;
    longestCallConnected: number;
    callsOnHold: number;
    longestCallOnHold: number;
    agentsReady: number;
    longestAgentReady: number;
    agentsNotReady: number;
    longestAgentNotReady: number;
    agentsUnavailable: number;
    longestAgentUnavailable: number;
    agentsBusy: number;
    longestAgentBusy: number;
    agentsLoggedIn: number;
    agentsOnAcdCall: number;
    currentShiftName: string;
    sawtCurrentShift: number;
    sawtPrevious30Mins: number;
    sawtPreviousHour: number;
    sawtPrevious4Hours: number;
    sawtPrevious8Hours: number;
    sawtPrevious24Hours: number;
    lastUpdateTimestamp: string;
}

export const QueueMetricTypeMap: Record<string, metricType> = {
    callsInQueue: 'COUNTER',
    longestCallInQueue: 'TIMER',
    voiceCalls: 'COUNTER',
    textCalls: 'COUNTER',
    rttCalls: 'COUNTER',
    ttyCalls: 'COUNTER',
    callsRinging: 'COUNTER',
    longestCallRinging: 'TIMER',
    callsWaiting: 'COUNTER',
    longestWaitTime: 'TIMER',
    averageWaitTime: 'TIMER',
    callsConnected: 'COUNTER',
    longestCallConnected: 'TIMER',
    callsOnHold: 'COUNTER',
    longestCallOnHold: 'TIMER',
    agentsReady: 'COUNTER',
    longestAgentReady: 'TIMER',
    agentsNotReady: 'COUNTER',
    longestAgentNotReady: 'TIMER',
    agentsUnavailable: 'COUNTER',
    longestAgentUnavailable: 'TIMER',
    agentsBusy: 'COUNTER',
    longestAgentBusy: 'TIMER',
    agentsLoggedIn: 'COUNTER',
    agentsOnAcdCall: 'COUNTER',
    sawtCurrentShift: 'TIMER',
    sawtPrevious30Mins: 'TIMER',
    sawtPreviousHour: 'TIMER',
    sawtPrevious4Hours: 'TIMER',
    sawtPrevious8Hours: 'TIMER',
    sawtPrevious24Hours: 'TIMER',
};

export interface QueueMetricObjTableData extends QueueMetricObj {
    priorityThreshold?: number;
    columnThresholds?: Record<string, ColumnThreshold>;
}
