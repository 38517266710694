/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, Inc.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ServiceHealth } from '../model/health';
import { BehaviorSubject, interval, Observable, of, retry, throwError } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { ServiceName } from '../../../assets/config/environment';

export abstract class HealthService {
    protected serviceName: ServiceName;

    protected retryAttempts = 1;
    protected retryDelay = 5000;
    protected pingInterval = 10000;
    protected healthEndpoint = '/actuator/health/readiness';
    protected failure = false;
    protected activeUrlIndex = 0;
    protected urls: string[] = [];

    public activeUrl$ = new BehaviorSubject<string>('');

    protected constructor(protected http: HttpClient, protected name: ServiceName, protected endpoints: string[]) {
        this.serviceName = name;
        this.urls = endpoints;
        this.activeUrl$.next(this.urls[0]);
        this.initializeHealthChecks();
    }

    protected initializeHealthChecks() {
        interval(this.pingInterval).pipe(
            startWith(0),
            switchMap(() => this.pingUrls(this.serviceName, this.urls))
        ).subscribe({
            next: ({ status }) => {
                console.info(`${this.serviceName} Service Health: ${status}`);
            },
            error: (err: HttpErrorResponse) => {
                console.error(`${this.serviceName} Service Health Error: ${err.message}`);
            }
        });
    }

    protected pingUrls(serviceName: string, urls: string[]): Observable<ServiceHealth> {
        return this.pingUrl(urls[this.activeUrlIndex]).pipe(
            catchError((err) => {
                this.setActiveIndex((this.activeUrlIndex + 1) % urls.length);
                console.info(`${serviceName} Retry exceeded, incrementing URL index`, this.activeUrlIndex);
                return of(err);
            }),
            retry({
                count: this.retryAttempts,
                delay: this.retryDelay
            }),
        );
    }

    protected setActiveIndex(newIndex: number) {
        this.activeUrlIndex = newIndex;
        if (this.activeUrl$.value !== this.urls[newIndex]) {
            this.activeUrl$.next(this.urls[newIndex]);
        }
    }

    protected pingUrl(url: string) {
        return this.http.get<ServiceHealth>(url.concat(this.healthEndpoint)).pipe(
            switchMap((res) => {
                if (res.status === 'DOWN') {
                    return throwError(() => ({ message: 'Service down' }));
                }

                return of(res);
            }),
            catchError((err) => {
                return throwError(() => err);
            })
        );
    }
}
